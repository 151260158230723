import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { db } from './firebase'; 
import { doc, getDoc } from "firebase/firestore";
import { Vortex } from 'react-loader-spinner';

const ProtectedRoute = ({ element, adminOnly }) => {
  const { currentUser } = useContext(AuthContext);
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserRole = async () => {
      if (currentUser) {
        try {
          const userDocRef = doc(db, 'users', currentUser.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            setRole(userData.role);
          } else {
            console.log('User document not found');
          }
        } catch (error) {
          console.error("Error fetching user role: ", error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchUserRole();
  }, [currentUser]);

  if (loading) {
    return <div> <Vortex
    visible={true}
    height="300"
    width="300"
    ariaLabel="vortex-loading"
    wrapperStyle={{}}
    wrapperClass="vortex-wrapper"
    colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
    /></div>;
  }

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (adminOnly && role !== 'admin') {
    return <Navigate to="/user-dashboard" />;
  }

  if (!adminOnly && role === 'admin') {
    return <Navigate to="/admin-dashboard" />;
  }

  return element;
};

export default ProtectedRoute;