import React, { useEffect, useState, useContext } from 'react';
import { db } from '../../firebase'; // Adjust path to your Firebase config
import { collection, query, where, getDocs } from 'firebase/firestore'; // Firestore functions
import { useAuth } from '../../AuthContext'; // Auth context for current user
import { Vortex } from 'react-loader-spinner';
import './Order.css'; // Add your custom CSS

const Order = () => {
  const { currentUser } = useAuth(); // Get the current logged-in user
  const [orders, setOrders] = useState([]); // Store orders for display
  const [loading, setLoading] = useState(true); // Loading state

  // Fetch orders based on current user's UID
  useEffect(() => {
    const fetchOrders = async () => {
      if (!currentUser) {
        setLoading(false); // If no user, stop loading
        return;
      }

      try {
        // Query Firestore 'orders' collection where userId matches the current user's UID
        const ordersQuery = query(
          collection(db, 'orders'), // Reference to 'orders' collection
          where('userId', '==', currentUser.uid) // Fetch orders where userId matches current user UID
        );

        // Execute the query and get the matching documents
        const querySnapshot = await getDocs(ordersQuery);
        const fetchedOrders = [];

        // Loop through the documents in the query result and push them to the orders array
        querySnapshot.forEach((doc) => {
          fetchedOrders.push({ id: doc.id, ...doc.data() });
        });

        // Set the fetched orders in state
        setOrders(fetchedOrders);
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchOrders();
  }, [currentUser]); // Only run this when currentUser changes

  // Show a loading spinner while fetching orders
  if (loading) {
    return (
      <div className="order-loading">
        <Vortex
          visible={true}
          height="200"
          width="200"
          ariaLabel="vortex-loading"
          wrapperStyle={{}}
          wrapperClass="vortex-wrapper"
          colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
        />
      </div>
    );
  }

  // Show a message if no orders are found
  if (orders.length === 0) {
    return <div>No orders found</div>;
  }

  // Display the orders that belong to the current user
  return (
    <div className="order-container">
      <h2>Your Orders</h2>
      <div className="order-list">
        {orders.map((order) => (
          <div className="order-item" key={order.id}>
            <h3>Order ID: {order.id}</h3>
            <p><strong>Date:</strong> {new Date(order.timestamp?.seconds * 1000).toLocaleDateString()}</p>
            <p><strong>Status:</strong> {order.status}</p>
            <p><strong>Total:</strong> N {order.total}</p>

            <div className="order-products">
              <h4>Ordered Products:</h4>
              {order.products.map((product, index) => (
                <div className="product-item" key={index}>
                  <p>{product.name}</p>
                  <p>Price: N {product.price}</p>
                  <p>Quantity: {product.quantity}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Order;
