import React, { useContext } from 'react'; 
import { CartContext } from '../../CartContext'; 
import { useAuth } from '../../AuthContext'; 
import { Link } from 'react-router-dom'; 
import './Cart.css'; 

const Cart = () => {
  const { cartItems, increaseItemQuantity, decreaseItemQuantity, updateCartItemQuantity, removeFromCart } = useContext(CartContext);
  const { currentUser } = useAuth();

  // If no user is logged in, display a message
  if (!currentUser) {
    return (
      <div className="cart">
        <h2>Shopping Cart</h2>
        <p>Please <Link to="/login" className="login-link">log in</Link> to view your cart.</p>
      </div>
    );
  }

  // Handle changes in quantity directly
  const handleQuantityChange = (productId, event) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (newQuantity > 0) {
      updateCartItemQuantity(productId, newQuantity); // Call updateCartItemQuantity from context
    }
  };

  const totalPrice = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

  return (
    <div className="cart">
      <h2>The list of Items in your Cart</h2>
      {cartItems.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        <div className='cart-container'>
          <ul className="cart-items">
            {cartItems.map((item) => (
              <li key={item.id}>
                <img src={item.imageUrl} alt={item.title} />
                <div>
                  <h3>{item.name}</h3>
                  <p>Price: ₦{item.price}</p>
                  <p>
                    Quantity:
                    <div className='cart-increase-buttons'>
                      <b onClick={() => decreaseItemQuantity(item.id)}>-</b>
                      <input
                        type="number"
                        value={item.quantity}
                        onChange={(event) => handleQuantityChange(item.id, event)} // Allow quantity change
                      />
                      <b onClick={() => increaseItemQuantity(item.id)}>+</b>
                    </div>
                  </p>
                  <button onClick={() => removeFromCart(item.id)}>Remove</button>
                </div>
              </li>
            ))}
          </ul>

          <div className="cart-total">
            <h3>Total Amount: ₦{totalPrice.toFixed(2)}</h3>
          </div>

          <Link to='/user-dashboard/checkout' className="checkout-button">
            Proceed to Checkout
          </Link>
        </div>
      )}
    </div>
  );
};

export default Cart;
