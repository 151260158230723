import react from "react"
import "./Advert.css"
import { FaPrint, FaShippingFast, FaPenFancy, FaStar, FaUsers } from 'react-icons/fa';

const Advert = () =>{
    const adverts = [
        {
          id: 1,
          message: 'Get your high-quality prints at affordable prices, fast delivery, and exceptional customer service guaranteed!',
          icon: <FaPrint size={50} color="#e898cb" />,
        },
        {
          id: 2,
          message: 'Fast, reliable shipping for all your printing needs. We ensure your projects arrive on time!',
          icon: <FaShippingFast size={50} color=" #3498db " />,
        },
        {
          id: 3,
          message: 'Customize your designs with our easy-to-use tools, and bring your ideas to life instantly.',
          icon: <FaPenFancy size={50} color="#2ecc71" />,
        },
        {
          id: 4,
          message: 'Trusted by thousands of happy customers, we offer 5-star quality printing services for any occasion.',
          icon: <FaStar size={50} color="#f1c40f" />,
        },
        {
          id: 5,
          message: 'Join our community of creative individuals and businesses benefiting from our premium print solutions today.',
          icon: <FaUsers size={50} color=" #3498db" />,
        },
        {
            id: 6,
            message: 'Take advantage of our special offers to make your printing even more affordable.',
            icon: <FaUsers size={50} color="#fe5cb6"/>,
          },
      ];
    

    return(
        <div className="advert-container">
            <div className="advert-box">
        {adverts.map((ad) => (
             
          <div key={ad.id} className="advert">
            {ad.icon}<br /><br />{ad.message}
          </div>
          
        ))}
        </div>
      </div>
    )
}

export default Advert