import React, { useState, useEffect } from 'react';
import ProductsList from '../components/products/ProductList';
import Hero from '../components/Hero';
import Hero2 from '../components/Hero2';
import ImageSlider from '../components/ImageSlider';
import images from '../components/products/Image';
import ImagePopup from '../components/products/ImagePopup';
import Newsletter from '../components/NewsLetter';
import ScrollAnimation from '../components/ScrollAnimation';
import Advert from '../components/Advert';
import './Home.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Home = () => {
  const [showPopup, setShowPopup] = useState(true);
 
  const imagesList = [
    '/images/book2.png',
    '/images/cloth1.png',
    '/images/bag5.png',
    '/images/box3.png',
  ];

  const videoUrl = 'https://www.youtube.com/embed/4yrpJn0kNNM';

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 3000);

    return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };


  return (
    <div className='home'>
      <Helmet>
        <title>Multibrand Digital Services</title>
        <meta name="description" content="We provide top-notch digital printing and branding services." />
        <meta property="og:title" content="Multibrand Digital Services" />
        <meta property="og:image" content="/images/book-image.png" />
        <link rel="canonical" href="https://multibrandprints.com.ng/" />
      </Helmet>
      <div className="home-page">
        {showPopup && <ImagePopup images={imagesList} videoUrl={videoUrl} onClose={handleClosePopup} />}
      </div>
      <Hero2 />
      <Hero />
      <ImageSlider images={images} />
      <ProductsList />
      <div className='newsletter-container'>
          <ScrollAnimation />
        
      </div>
      <Advert />
      <Newsletter />
    
    </div>
  );
};

export default Home;
