import React from 'react';
import { Link, useParams } from 'react-router-dom';
import ProductArray from './ProductArray.js';
import './SingleProduct.css';
import GoBackComponent from '../GoBackComponent.jsx';

const SingleProduct = () => {
  const { productName, productId } = useParams();
  const products = ProductArray[productName] || [];
  const product = products.find((p) => p.id === parseInt(productId, 10));

  // Fetch related products (randomly select 3 from the same category)
  const relatedProducts = products.filter((p) => p.id !== parseInt(productId, 10)).slice(0, 3);

  return (
    <div className="single-product">
      <h1>{product?.name}</h1>
      {product ? (
        <div className="single-product-box">
          <img className="main-image" src={product.image} alt={product.name} />
          <p className="quantity">MIN: {product.quantity} Units(s)</p>

          <div className="single-product-details">
            <h3>
              Product Name: <strong>{product.name}</strong>
            </h3>
            <h3>
              Product Price: <strong>N {product.price}</strong>
            </h3>
            <h3>
              Category: <strong>{product.category || 'N/A'}</strong>
            </h3>
            

            <h4>Product Description:</h4>
            <p>{product.description}</p>

            <ul className="features-list">
              {product?.features?.map((feature, index) => (
                <li key={index}>{feature}</li>
              )) || <li>No features available</li>}
            </ul>

            <div className="product-navigation">
              <h3>Quick Actions:</h3>
              <h3>
                Click{' '}
                <Link style={{ color: 'aqua' }} to={`https://wa.me/2348023879629`}>
                  HERE
                </Link>{' '}
                For A Quick Order
              </h3>
              <h3>
                Visit the{' '}
                <Link style={{ color: 'aqua' }} to="about">
                  Company Profile
                </Link>
              </h3>
            </div>

            <GoBackComponent />
          </div>
        </div>
      ) : (
        <p>Product not found</p>
      )}

      {/* Related Products Section */}
      <div className="relate-products">
        <h3>Related Products</h3>
        <div className="relate-products-grid">
          {relatedProducts.map((relatedProduct) => (
            <div className="relate-product-item" key={relatedProduct.id}>
              <img src={relatedProduct.image} alt={relatedProduct.name} />
              <p>{relatedProduct.name}</p>
              <p>Price: N {relatedProduct.price}</p>
              <Link to={`/products/${productName}/${relatedProduct.id}`}>View Product</Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SingleProduct;
