import React from 'react';
import { Link, useParams} from 'react-router-dom';
import './SecondaryProducts.css';  
import ProductArray from './ProductArray';
import GoBackComponent from '../GoBackComponent';
import { SlArrowRight } from 'react-icons/sl';


const SecondaryProducts = () => {
  const { productName } = useParams();
  const products = ProductArray[productName] || [];

  return (
    <div className='secondary-product'>
      <p>High-quality printing services for all your needs – fast, reliable, and affordable!</p>
      <br />
      <h1>{productName}</h1>
      <hr />
      <div  className='secondary-product-container'>
        {products.map((product) => (
          <div key={product.id}  className='secondary-product-box'>
           
            <img src={product.image} alt="" />

            <div className='view-product-details'>
            <h3>{product.name}</h3>
            <p>Delivery: <span>{product.delivery} working days</span></p>
            
            <Link to= {`/products/${productName}/${product.id}`}  className='view-product'> View Details...<SlArrowRight/></Link>
            <GoBackComponent/>
            </div>
        
          </div>
        ))}
      </div>
    </div>
  );
};

export default SecondaryProducts;