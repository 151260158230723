import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';
import './AdminProductList.css';
import { Vortex } from 'react-loader-spinner';
import { SlArrowRight } from "react-icons/sl";
const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [loading, setLoading] = useState(true);  // To handle loading state
  const navigate = useNavigate();

  // Predefined categories
  const predefinedCategories = ['Books', 'Bags', 'Letterhead', 'Branding', 'Clothes', 'Fliers', 'Business Cards', 'Caps', 'Custom Mugs', 'Calendar',];

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);  // Set loading before fetching
      try {
        const productCollection = collection(db, 'products');
        const productSnapshot = await getDocs(productCollection);
        const productsList = productSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setProducts(productsList); // Initialize with all products
        setLoading(false);  // Stop loading after fetching
      } catch (err) {
        console.error('Error fetching products:', err);
        setLoading(false);  // Stop loading on error
      }
    };

    const fetchCategories = async () => {
      try {
        const categoryCollection = collection(db, 'categories');
        const categorySnapshot = await getDocs(categoryCollection);
        const fetchedCategories = categorySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Merge predefined categories with fetched categories, avoiding duplicates
        const allCategories = [...new Set([...predefinedCategories, ...fetchedCategories.map(c => c.name)])];
        setCategories(allCategories.map(name => ({ id: name, name }))); // Normalize to an array of objects
      } catch (err) {
        console.error('Error fetching categories:', err);
      }
    };

    fetchProducts();
    fetchCategories();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCategoryChange = async (e) => {
    const categoryName = e.target.value;
    setSelectedCategory(categoryName);

    setLoading(true);  // Set loading while changing category
    try {
      const productCollection = collection(db, 'products');
      let productSnapshot;

      if (categoryName) {
        const q = query(productCollection, where('categoryName', '==', categoryName));
        productSnapshot = await getDocs(q);
      } else {
        productSnapshot = await getDocs(productCollection);
      }

      const productsList = productSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProducts(productsList);
      setLoading(false);  // Stop loading after fetching
    } catch (err) {
      console.error('Error fetching products:', err);
      setLoading(false);  // Stop loading on error
    }
  };

  const handleCategoryClick = (categoryName) => {
    navigate(`/category/${categoryName}`);
  };

  if (loading) return <loading> <Vortex
    visible={true}
    height="300"
    width="300"
    ariaLabel="vortex-loading"
    wrapperStyle={{}}
    wrapperClass="vortex-wrapper"
    colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
    /></loading>;

  return (
    <div className='product-list'>
      <h1>Browse All Products Collection</h1>
      <div>
        <select value={selectedCategory} onChange={handleCategoryChange} className='product-select'>
          <option value=''>All Categories</option>
          {categories.map((category) => (
            <option key={category.id} value={category.name}>{category.name}</option>
          ))}
        </select>
      </div>

      <div className='product-container'>
        {products.map((product) => (
          <div key={product.id} className='product-box'>
            <Link to={`/product/${product.id}`}>
              <img src={product.imageUrl} alt={product.name} />
            </Link>
            <h3>{product.name}</h3>
            <p>{product.description}</p>
            <p>As low as <b>₦{product.price}</b> per {product.quantity}</p>
            
            <div className="productlist-button">
              <a href={`/product/${product.id}`}>
                <button className='view-product-button'>View Product </button>
              </a>
              <button 
                className='explore-more-button' 
                onClick={() => handleCategoryClick(product.categoryName)}
              >Explore More..<SlArrowRight /></button>


            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductList;
