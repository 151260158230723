import React, { useEffect, useState, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { CartContext } from '../../CartContext';
import './SingleProductPage.css';
import GoBackComponent from '../GoBackComponent';
import { useAuth } from '../../AuthContext';
import { Vortex } from 'react-loader-spinner';

const SingleProductPage = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState('');
  const { addToCart } = useContext(CartContext);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [relatedProducts, setRelatedProducts] = useState([]);

  useEffect(() => {
    const fetchProduct = async () => {
      const productDoc = doc(db, 'products', productId);
      const productSnapshot = await getDoc(productDoc);
      if (productSnapshot.exists()) {
        const fetchedProduct = { id: productSnapshot.id, ...productSnapshot.data() };
        setProduct(fetchedProduct);
        setSelectedImage(fetchedProduct.imageUrl);
        fetchRelatedProducts(fetchedProduct.categoryName); // Ensure this matches your Firestore structure
      } else {
        console.error('Product not found');
      }
    };

    const fetchRelatedProducts = async (category) => {
      try {
        const productsRef = collection(db, 'products');
        const q = query(productsRef, where('categoryName', '==', category)); // Ensure category field matches
        const querySnapshot = await getDocs(q);
        const related = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setRelatedProducts(related.filter(item => item.id !== productId).slice(0, 6)); // Exclude current product
      } catch (error) {
        console.error('Error fetching related products:', error);
      }
    };

    fetchProduct();
  }, [productId]);

  if (!product) {
    return (
      <div>
        <Vortex
          visible={true}
          height="400"
          width="400"
          ariaLabel="vortex-loading"
          wrapperStyle={{}}
          wrapperClass="vortex-wrapper"
          colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
        />
      </div>
    );
  }

  const handleAddToCart = async () => {
    if (!currentUser) {
      navigate('/login');
    } else {
      try {
        const cartItem = {
          id: product.id,
          name: product.name,
          price: product.price,
          imageUrl: product.imageUrl,
          userId: currentUser.uid,
        };
        await addToCart(cartItem);
        alert('Added to cart successfully!');
      } catch (error) {
        console.error('Error adding to cart:', error);
      }
    }
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  return (
    <div className="single-product">
      <h2>{product.name}</h2>
      <div className='single-product-box'>
      <p className='quantity'>MIN: {product.quantity} Unit(s)</p>
        <img
          src={selectedImage}
          alt={product.name}
          className="main-image"
          onClick={() => setSelectedImage(product.imageUrl)}
        />
        <div className="image-thumbnails">
          <img
            src={product.imageUrl}
            alt="Main"
            className="thumbnail"
            onClick={() => handleImageClick(product.imageUrl)}
          />
          {product.detailImages?.map((imgUrl, index) => (
            <img
              key={index}
              src={imgUrl}
              alt={`Detail ${index + 1}`}
              className="thumbnail"
              onClick={() => handleImageClick(imgUrl)}
            />
          ))}
        </div>
        <div className='single-product-details'>
          <h3>Product Name: <strong>{product.name}</strong></h3>
          <p>Product Price: <strong>N {product.price} Per {product.quantity}</strong></p>
          <h4>Product Description:</h4>
          <p>{product.description}</p>
          <h4>Features:</h4>
          <ul className="features-list">
            {product.features?.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
         
          <p className='rating'>Rating: {product.rating} / 5</p>
        </div>
      </div>

      <div className="cart-button">
        <button onClick={handleAddToCart} className='add-to-cart-button'>Add to Cart</button>
        <Link to={currentUser ? "/user-dashboard/cart" : "/login"}>
          <button className='add-to-cart-button'>View Cart</button>
        </Link>
        <GoBackComponent />
      </div>

      {/* Related Products Section */}
      <div className="relate-products">
        <h3>Related Products</h3>
        <div className="relate-products-grid">
          {relatedProducts.map((relatedProduct) => (
            <Link to={`/product/${relatedProduct.id}`}>
            <div key={relatedProduct.id} className="relate-product-item">
                <img src={relatedProduct.imageUrl} alt={relatedProduct.name} />
                <p>{relatedProduct.name}</p> <br />
                <p>N{relatedProduct.price} Per {relatedProduct.quantity}</p>
            </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SingleProductPage;
