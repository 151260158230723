import React, { useState, useContext } from 'react';
import { CartContext } from '../../CartContext';
import { db, storage } from '../../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Import storage functions
import emailjs from 'emailjs-com';
import { PaystackButton } from 'react-paystack';
import './Checkout.css';

const Checkout = () => {
  const { cartItems, clearCart } = useContext(CartContext);
  const [hasDesign, setHasDesign] = useState(true);
  const [file, setFile] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;
  const totalPrice = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  const amount = totalPrice * 100; // Paystack expects the amount in kobo

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const submitOrder = async () => {
    setLoading(true);  // Show loading state
    let fileUrl = null;

    try {
      // If a file is provided, upload it to Firebase Storage
      if (file) {
        const storageRef = ref(storage, `designs/${file.name}`);
        const snapshot = await uploadBytes(storageRef, file); // Upload the file
        fileUrl = await getDownloadURL(snapshot.ref); // Get the file's download URL
      }

      const orderData = {
        customerDetails: {
          name,
          email,
          phone,
          address,
        },
        items: cartItems,
        timestamp: serverTimestamp(),
        designFile: fileUrl, // Store the file URL if uploaded
      };

      console.log('Order data to be submitted:', orderData);

      // Submit order to Firestore
      await addDoc(collection(db, 'orders'), orderData);
      console.log('Order successfully submitted to Firestore.');

      // Send confirmation email
      const emailData = {
        to_name: name,
        to_email: email,
        message: `Thank you for your order! We have received your order and will process it shortly.`,
      };

      console.log('Sending confirmation email...', emailData);

      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        emailData,
        process.env.REACT_APP_EMAILJS_USER_ID
      );

      console.log('Confirmation email sent successfully.');

      setSuccessMessage('Order submitted successfully! Payment was successful.');
      clearCart(); // Clear the cart after order submission
    } catch (error) {
      console.error('Error submitting order:', error.message);
      setErrorMessage('Failed to submit order after payment. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const onPaymentSuccess = async () => {
    console.log('Payment successful. Starting order submission...');
    await submitOrder();
  };

  const componentProps = {
    email,
    amount,
    publicKey,
    text: "Pay Now",
    onSuccess: onPaymentSuccess,
    onClose: () => {
      console.log("Payment window closed without completing.");
      alert("Payment was not completed. Please try again.");
    },
  };

  return (
    <div className="checkout">
      <h2>Checkout</h2>
      <div className="checkout-box">

        {/* Cart Summary */}
        <div className="cart-summary">
          <h3>Your Items</h3>
          {cartItems.length === 0 ? (
            <p>Your cart is empty.</p>
          ) : (
            <ul>
              {cartItems.map((item) => (
                <li key={item.id}>
                  <div className="cart-item">
                    <img src={item.imageUrl} alt={item.name} className="cart-item-image" />
                    <div className="cart-item-details">
                      <h4>{item.name}</h4>
                      <p>Quantity: {item.quantity}</p>
                      <p>Price: ₦{(item.price * item.quantity).toLocaleString()}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Design Upload Form */}
        <div className="design-upload-form">
          <form>
            <h2>Upload Your Design</h2>
            <div className="checkout-design">
              <div>
                <label className='design-label'>
                  If you don't have a design, click on the blue icon
                  <input
                    type="checkbox"
                    checked={hasDesign}
                    onChange={(e) => setHasDesign(e.target.checked)}
                  />
                </label>
              </div>
              {hasDesign && (
                <div>
                  <label className='design-label'>
                    Upload your design (Formats: CDR, PNG, JPG, JPEG):
                  </label>
                  <input
                    type="file"
                    accept=".cdr,.png,.jpg,.jpeg"
                    onChange={handleFileChange}
                    required
                  />
                </div>
              )}
            </div>

            {/* Customer Information Form */}
            <div className="checkout-form">
              <div>
                <label>Name:</label>
                <input
                  type="text"
                  value={name}
                  placeholder='Your name here.......' 
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div>
                <label>Email:</label>
                <input
                  type="email"
                  value={email}
                  placeholder='Your email here.......' 
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div>
                <label>Phone:</label>
                <input
                  type="text"
                  value={phone}
                  placeholder='Your phone number here.......' 
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
              <div>
                <label>Address:</label>
                <input
                  type="text"
                  value={address}
                  placeholder='Your delivery address  here.......' 
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </div>
            </div>

            {/* Total Price */}
            <div className="total-price">
              <h3>Total Price: ₦{totalPrice.toLocaleString()}</h3>
            </div>

            {/* Paystack Payment Button */}
            <PaystackButton className="paystack-button" {...componentProps} />

          </form>
          {successMessage && <p className="success-message">{successMessage}</p>}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          {loading && <p className="loading-message">Submitting order, please wait...</p>}
        </div>
      </div>
    </div>
  );
};

export default Checkout;
