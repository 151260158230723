import React from 'react'
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import "./Footer.css";
import { MdEmail } from 'react-icons/md';
import Contact from '../pages/Contact';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className='footer'>
        <div className='footer-one'>
        <Contact/>
        </div>

        <div className='footer-paystack-image'>
        <img src="/images/paystack.png" alt="" />
         <div className="footer-social">
            <a href="https://facebook.com/multibrandprint"><FaFacebook/></a>
            <a href="https://instagram.com/multibrandprints"><FaInstagram/></a>
            <a href="https://twitter.com/oguntoyintaiwo"><FaTwitter/></a>
            <a href="mailto:multibranddigital@gmail.com"><MdEmail/></a>
         </div>
         </div>

         <div className="footer-links">
         
             <a href={"/about"}><h4>About Us</h4></a> 
         <a href={"/term-condition"}><h4>T & C</h4></a> 
         <a  href={"/feedback"}><h4>Send Review</h4></a>
         <a href={"/feedbacklist"}><h4>View Our customer Reviews</h4></a> 
          
            <p className='first-image'> Thank you for choosing Multibrand Digital Services, where your ideas take shape and come to life.</p>

         </div>
         <p className='footer-under'><b>Multibrand Digital Services.</b> <br />2024 © All rights reserved. facilitated by. <Link to={"https://oguntoyinbo.vercel.app"}> <strong style={{color:"royalBlue"}}> MultiBrand.</strong></Link></p>
       </footer>
  )
}

export default Footer