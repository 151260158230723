import React, { useEffect, useState } from 'react';
import { db, storage } from '../../firebase'; // Assuming storage is initialized in your firebase config
import { collection, getDocs, onSnapshot, deleteDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../../AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AdminDashboard.css';
import AddProductForm from './AddProductForm';
import UserFeedbackList from '../User/UserFeedbackList';
import UserList from '../User/UserList';
import { Vortex } from 'react-loader-spinner';
import AdminChatDashboard from './AdminChatDashboard';


const AdminDashboard = () => {
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [newImage, setNewImage] = useState(null); // For storing new image
  const [userRole, setUserRole] = useState(null);

  // States for controlling visibility of sections
  const [showOrders, setShowOrders] = useState(false);
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [showProductList, setShowProductList] = useState(false);
  const [showUserFeedback, setShowUserFeedback] = useState(false);
  const [showUserList, setShowUserList] = useState(false);

  const { currentUser } = useAuth();

  // Fetch the current user's role from Firestore
  const fetchUserRole = async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        setUserRole(userDoc.data().role);
      } else {
        setError('User does not exist');
      }
    } catch (err) {
      setError('Failed to fetch user role');
      console.error('Error fetching user role:', err);
    }
  };

  // Fetch products from Firestore
  const fetchProducts = async () => {
    try {
      const productCollection = collection(db, 'products');
      const productSnapshot = await getDocs(productCollection);
      setProducts(productSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    } catch (err) {
      setError('Failed to fetch products');
      console.error('Error fetching products:', err);
    }
  };

  // Fetch orders from Firestore
  const fetchOrders = async () => {
    try {
      const ordersCollection = collection(db, 'orders');
      const ordersSnapshot = await getDocs(ordersCollection);
      setOrders(ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    } catch (err) {
      setError('Failed to fetch orders');
      console.error('Error fetching orders:', err);
    }
  };

  // Delete product from Firestore
  const deleteProduct = async (productId) => {
    try {
      await deleteDoc(doc(db, 'products', productId));
      toast.success('Product deleted successfully!');
      fetchProducts(); // Refresh the product list
    } catch (error) {
      console.error('Error deleting product:', error);
      toast.error('Failed to delete product. Please try again.');
    }
  };

  // Start editing a product
  const startEditing = (product) => {
    setCurrentProduct(product);
    setIsEditing(true);
  };

  // Handle product update
  const handleProductUpdate = async (event) => {
    event.preventDefault();

    try {
      let imageUrl = currentProduct.imageUrl; // Keep existing image URL

      // If a new image is selected, upload it to Firebase Storage
      if (newImage) {
        const imageRef = ref(storage, `products/${newImage.name}`);
        await uploadBytes(imageRef, newImage);
        imageUrl = await getDownloadURL(imageRef);
      }

      // Update the product in Firestore
      const productDoc = doc(db, 'products', currentProduct.id);
      await updateDoc(productDoc, { ...currentProduct, imageUrl });
      toast.success('Product updated successfully!');
      fetchProducts(); // Refresh the product list
      setIsEditing(false);
      setNewImage(null); // Reset image input
    } catch (error) {
      console.error('Error updating product:', error);
      toast.error('Failed to update product. Please try again.');
    }
  };

  // Handle input change in product form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentProduct({ ...currentProduct, [name]: value });
  };

  // Handle new image selection
  const handleImageChange = (e) => {
    setNewImage(e.target.files[0]);
  };

  // Handle new product addition
  const handleProductAdded = async () => {
    try {
      await fetchProducts(); // Refresh the product list
      toast.success('Product added successfully!');
    } catch (error) {
      console.error('Error adding product:', error);
      toast.error('Failed to add product. Please try again.');
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchUserRole(currentUser.uid); // Fetch the user role on mount
    }
  }, [currentUser]);

  useEffect(() => {
    if (userRole === 'admin') {
      fetchProducts(); // Fetch products on mount for admins
      fetchOrders();   // Fetch orders on mount for admins
      setLoading(false);

      // Real-time listener for orders
      const unsubscribe = onSnapshot(collection(db, 'orders'), (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            toast.info('A new order has been submitted!');
            fetchOrders(); // Refresh orders list on new order
          }
        });
      });

      return () => unsubscribe(); // Clean up the listener on unmount
    } else if (userRole) {
      setLoading(false); // Set loading to false if user is not an admin
    }
  }, [userRole]);

  if (loading) {
    return (
      <div className="loading">
        <Vortex
          visible={true}
          height="300"
          width="300"
          ariaLabel="vortex-loading"
          wrapperStyle={{}}
          wrapperClass="vortex-wrapper"
          colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
        />
      </div>
    );
  }

  if (!currentUser || userRole !== 'admin') {
    return <p className="loading">Access Denied. You do not have permission to view this page.</p>;
  }

  if (error) {
    return <p className="error">{error}</p>;
  }

  return (
    <div className="admin-dashboard">
      <h2>Admin Dashboard</h2>

      {/* Toggle buttons to show/hide sections */}
      <div className="admin-buttons">
        <button onClick={() => setShowAddProduct(!showAddProduct)}>
          {showAddProduct ? 'Close Add Product Form' : 'Open Add Product Form'}
        </button>
        <button onClick={() => setShowProductList(!showProductList)}>
          {showProductList ? 'Close Product List' : 'Open Product List'}
        </button>
        <button onClick={() => setShowOrders(!showOrders)}>
          {showOrders ? 'Close Orders' : 'Open Orders'}
        </button>
        <button onClick={() => setShowUserFeedback(!showUserFeedback)}>
          {showUserFeedback ? 'Close Feedbacks' : 'Open Feedbacks'}
        </button>
        <button onClick={() => setShowUserList(!showUserList)}>
          {showUserList ? 'Close User List' : 'Open User List'}
        </button>
      </div>  

      {/* Conditionally render AddProductForm */}
      {showAddProduct && <AddProductForm onProductAdded={handleProductAdded} />}

      {showUserList && <UserList />}

      {/* Conditionally render Product List */}
      {showProductList && (
        <div className="added-products">
          <h3>Added Products</h3>
          <ul>
            {products.map(product => (
              <li key={product.id}>
                {isEditing && currentProduct?.id === product.id ? (
                  <form onSubmit={handleProductUpdate}>
                    <input
                      type="text"
                      name="name"
                      value={currentProduct?.name || ''}
                      onChange={handleInputChange}
                      placeholder="Product Name"
                    />
                    <input
                      type="number"
                      name="price"
                      value={currentProduct?.price || ''}
                      onChange={handleInputChange}
                      placeholder="Product Price"
                    />
                    <input
                      type="text"
                      name="description"
                      value={currentProduct?.description || ''}
                      onChange={handleInputChange}
                      placeholder="Product Description"
                    />
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange} // New image input
                    />
                    <button type="submit">Update</button>
                    <button type="button" onClick={() => setIsEditing(false)}>
                      Cancel
                    </button>
                  </form>
                ) : (
                  <>
                    <h4>{product.name}</h4>
                    <p>{product.description}</p>
                    <p>${product.price}</p>
                    {product.imageUrl && (
                      <img src={product.imageUrl} alt={product.name} width="100" height="100" />
                    )}
                    <button onClick={() => startEditing(product)}>Edit</button>
                    <button onClick={() => deleteProduct(product.id)}>Delete</button>
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Conditionally render Orders */}
      {showOrders && (
        <div className="order-list">
          <h3>Orders</h3>
          <ul>
            {orders.map(order => (
              <li key={order.id}>
                <p>Order ID: {order.id}</p>
                <p>Total: ${order.total}</p>
                <p>Status: {order.status}</p>
              </li>
            ))}
          </ul>
        </div>
      )}

      {showUserFeedback && <UserFeedbackList />}
      <h1>CHATS</h1>
      <AdminChatDashboard/>
    

      <ToastContainer />
    </div>
  );
};

export default AdminDashboard;
