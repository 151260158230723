import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, onSnapshot, getDocs, deleteDoc, doc } from 'firebase/firestore';
import './AdminChatDashboard.css'; // Add your CSS for styling
import ChatComponent from './ChatComponent';

const AdminChatDashboard = () => {
  const [userChats, setUserChats] = useState([]); // Store unique user chats
  const [selectedUser, setSelectedUser] = useState(null); // Store selected user ID
  const [userNames, setUserNames] = useState({}); // State to hold user names
  const [confirmWipe, setConfirmWipe] = useState(false); // State for confirmation dialog

  useEffect(() => {
    // Fetch all users to map IDs to names
    const fetchUserNames = async () => {
      const usersSnapshot = await getDocs(collection(db, 'users')); // Assuming user data is stored in 'users' collection
      const names = {};
      usersSnapshot.forEach((doc) => {
        names[doc.id] = doc.data().name; // Adjust according to your user data structure
      });
      setUserNames(names);
    };

    fetchUserNames();

    // Fetch all chats and group them by unique user IDs
    const unsubscribe = onSnapshot(collection(db, 'chats'), (snapshot) => {
      const chatsByUser = {};
      snapshot.forEach((doc) => {
        const chat = doc.data();
        const { senderId, receiverId } = chat;

        // Determine who is the user (not admin)
        const userId = senderId !== 'adminId' ? senderId : receiverId; // Adjust for your admin ID

        if (!chatsByUser[userId]) {
          chatsByUser[userId] = [];
        }
        chatsByUser[userId].push(chat);
      });

      setUserChats(Object.keys(chatsByUser)); // Get unique user IDs
    });

    return () => unsubscribe();
  }, []);

  // Function to delete a chat message by ID
  const deleteMessage = async (messageId) => {
    try {
      await deleteDoc(doc(db, 'chats', messageId));
      console.log('Message deleted successfully');
    } catch (error) {
      console.error('Error deleting message:', error.message);
    }
  };

  // Function to wipe all chat history
  const wipeChatHistory = async () => {
    const chatsRef = collection(db, 'chats');
    const chatsSnapshot = await getDocs(chatsRef);

    const deletePromises = chatsSnapshot.docs.map(doc => deleteDoc(doc.ref));

    try {
      await Promise.all(deletePromises);
      console.log('All chat history wiped successfully');
      setConfirmWipe(false); // Close confirmation dialog
    } catch (error) {
      console.error('Error wiping chat history:', error.message);
    }
  };

  return (
    <div className="admin-chat-dashboard">
      <div className="users-list">
        <h3>Users</h3>
        <ul>
          {userChats.map((userId) => (
            <li
              key={userId}
              className={selectedUser === userId ? 'active' : ''}
              onClick={() => setSelectedUser(userId)} // Set the selected user ID when clicked
            >
              User: {userNames[userId] || userId} {/* Show user name or ID if not available */}
            </li>
          ))}
        </ul>
      </div>

      <div className="chat-box">
        {selectedUser ? (
          <ChatComponent
            key={selectedUser}
            receiverId={selectedUser}
            isAdmin={true}
            deleteMessage={deleteMessage} // Pass deleteMessage function to ChatComponent
          />
        ) : (
          <p>Select a user to see the chat</p>
        )}
      </div>

      {confirmWipe ? (
        <div className="confirm-wipe-container">
          <p>Are you sure you want to wipe all chat history?</p>
          <button onClick={wipeChatHistory} className="confirm-button">Yes</button>
          <button onClick={() => setConfirmWipe(false)} className="cancel-button">No</button>
        </div>
      ) : (
        <button onClick={() => setConfirmWipe(true)} className="wipe-chat-button">
          Wipe Chat History
        </button>
      )}
    </div>
  );
};

export default AdminChatDashboard;
