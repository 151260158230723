import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import ProductList from './components/products/ProductList';
import SecondaryProducts from './components/products/SecondaryProducts';
import SingleProduct from './components/products/SingleProduct';
import Navbar from './components/navbar/Navbar';
import { FaLongArrowAltUp, FaWhatsapp } from 'react-icons/fa';
import './App.css';
import Home from './pages/Home';
import Footer from './components/Footer';
import AboutUs from './pages/AboutUs';
import Category from './components/navbar/Category';
import Login from './components/admin/Login';
import AdminDashboard from './components/admin/AdminDashboard';
import FeedbackForm from './components/admin/FeedbackForm';
import ProtectedRoute from './ProtectedRoute'; 
import UserFeedbackList from './components/User/UserFeedbackList';
import SingleProductPage from './components/admin/SingleProductPage';
import AdminProductList from './components/admin/AdminProductList';
import { CartProvider } from './CartContext';
import SingleSearchProduct from './components/products/SingleSearchProduct';
import SingleImage from './components/products/SingleImage';
import Register from './components/admin/Register';
import UserChatComponent from './components/admin/UserChatComponent';
import { AuthProvider } from './AuthContext'; 
import ResetPassword from './components/admin/ResetPassword';
import Profile from './components/User/Profile';
import Orders from './components/User/Order';

import UserDashboard from './components/User/UserDashboard';

import Checkout from './components/admin/Checkout';
import Cart from './components/User/Cart';
import { DarkModeProvider } from './DarkModeContext';
import CompanyProfile from './components/CompanyProfile';
import { BiSolidPhoneCall } from 'react-icons/bi';

const App = () => {
  const [showScrollButton, setShowScrollButton] = useState(false); 

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll to top functionality
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <AuthProvider>
      <CartProvider>
        <DarkModeProvider> 
          <Router>
            <div className="call">
              <p>+234 802 929 9901, +234 912 960 7191</p>
              <Link to="tel:+2348029299901" className="call-icon"><p><BiSolidPhoneCall /></p></Link>
            </div>

            {/* Scroll to Top Button */}
            {showScrollButton && (
             <button className="scroll-to-top" onClick={scrollToTop}><FaLongArrowAltUp /></button>
           )}
 
            <Navbar /> 
            <a href="https://wa.me/2348029299901">
              <div className="homepage-watsap">
                <FaWhatsapp /> <span className='span1'>Click here to Discuss Your Printing Project with us on whatsapp!</span>
                <span className='span2'>Chat with us on whatsapp!</span>
              </div>
            </a>

            <Routes>
              {/* Public Routes */}
              <Route path="/" exact element={<Home />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/product-list" element={<ProductList />} />
              <Route path="/products/:productName" element={<SecondaryProducts />} />
              <Route path="/products/:productName/:productId" element={<SingleProduct />} />
              <Route path="/image/:id" element={<SingleImage />} />
              <Route path="/category/:category" element={<Category />} />
              <Route path="/search/:nameOrCategory" element={<SingleSearchProduct />} />
              <Route path="/orders" element={<Orders />} /> 
              
              {/* Auth Routes */}
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/reset-password" element={<ResetPassword />} />


              {/* Protected User Routes */}
              <Route path="/user-dashboard/*" element={<ProtectedRoute element={<UserDashboard />} />}>
                <Route path="profile" element={<Profile />} />
                <Route path="carts" element={<Cart />} />
                <Route path="checkout" element={<Checkout />} />
                <Route path="orders" element={<Orders />} />
                <Route path="user-chat" element={<UserChatComponent/>} />
              </Route>

              {/* Protected Admin Routes */}
              <Route path="/admin-dashboard" element={
              <ProtectedRoute element={<AdminDashboard />} adminOnly />
            } />
           

              {/* Feedback Form - Accessible to All Authenticated Users */}
              <Route path="/feedback" element={<FeedbackForm />} />
              <Route path="/company-profile" element={<CompanyProfile />} />
              <Route path="/adminproducts" element={<AdminProductList />} />
              <Route path="/feedbacklist" element={<UserFeedbackList />} />
              <Route path="/product/:productId" element={<SingleProductPage />} />
              
          
            </Routes>
            <div style={{bottom:"0", width:"100%",right:"0",}}>
            <Footer />
            </div>
          </Router>
        </DarkModeProvider>
      </CartProvider>
    </AuthProvider>
  );
};

export default App;