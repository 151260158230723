import React from 'react';
import { Link, useParams } from 'react-router-dom';
import ProductArray from './ProductArray';
import "./SingleSearchProduct.css";
import GoBackComponent from '../GoBackComponent';

const SingleSearchProduct = () => {
  const { nameOrCategory } = useParams();
  
  // Find the product based on the name
  const allProducts = Object.values(ProductArray).flat();
  const product = allProducts.find((prod) => 
    prod.name.toLowerCase() === nameOrCategory.toLowerCase()
  );

  return (
    <div className='single-search-component'>
      {product ? (
        <div className="single-search-product">
          <div className="main-product">
            <h2>{product.name}</h2>
            <img src={product.image} alt={product.name} />
            <p>{product.description}</p>
            <p className="price">Price: ₦{product.price}</p>

            <Link to={"https://wa.me/2348029299901"}>
              <div className="quick-order"> Click Here for a Quick Order </div>
            </Link>

            {/* Additional Product Information */}
            <div className="additional-info">
              <h3>Features</h3>
              <ul>
                {product.features && product.features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
            </div>
          </div>

          {/* Related Products Section */}
          <div className="related-products">
            <h3>Related Products</h3>
            <div className="related-products-grid">
              {allProducts.slice(0, 4).map((relatedProduct) => (
                <div key={relatedProduct.id} className="related-product-card">
                  <img src={relatedProduct.image} alt={relatedProduct.name} />
                  <h5>{relatedProduct.name}</h5>
                  <p>₦{relatedProduct.price}</p>
                  <Link to={`/product/${relatedProduct.id}`} className="view-product-button">View Product</Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <p>Product not found.</p>
      )}
      <GoBackComponent />
    </div>
  );
};

export default SingleSearchProduct;
