import React from 'react';
import "./ProductList.css"
import { SlArrowRight } from "react-icons/sl";

const primaryProducts = [
  { 
    name: 'Books', 
    id: 1, 
    image: "/images/book2.png", 
    price: "1000", 
    description: "High-quality printed books, perfect for educational, personal, or business use.", 
    quantity: 100 
  },
  { 
    name: 'Tshirt_Branding', 
    id: 2, 
    image: "/images/cloth1.png", 
    price: "5000", 
    description: "Custom-branded T-shirts for your events, business, or personal use.", 
    quantity: 50 
  },
  { 
    name: 'Bags', 
    id: 3, 
    image: "/images/bag1.png", 
    price: "1000", 
    description: "Durable, stylish bags for every occasion, including custom branding options.", 
    quantity: 200 
  },
  { 
    name: 'Branding', 
    id: 4, 
    image: "/images/brand1.png", 
    price: "15000", 
    description: "Comprehensive branding solutions, from logo design to full marketing collateral.", 
    quantity: 10 
  },
  { 
    name: 'Letterhead', 
    id: 5, 
    image: "/images/letterhead.png", 
    price: "2000", 
    description: "Custom-designed letterheads to give your business a professional edge.", 
    quantity: 500 
  },
  { 
    name: 'Custom_Mugs', 
    id: 6, 
    image: "/images/cup1.png", 
    price: "3000", 
    description: "Personalized mugs, perfect for gifts or business promotion.", 
    quantity: 150 
  },
  { 
    name: 'Business_Cards', 
    id: 7, 
    image: "/images/card2.png", 
    price: "1500", 
    description: "Professional business cards with custom designs to elevate your brand.", 
    quantity: 1000 
  },
  { 
    name: 'Calendar', 
    id: 8, 
    image: "/images/calender2.png", 
    price: "4000", 
    description: "Custom calendars, perfect for corporate branding and year-long promotion.", 
    quantity: 300 
  },
  { 
    name: 'Business_Stationeries', 
    id: 9, 
    image: "/images/company.png", 
    price: "10000", 
    description: "Complete business stationery sets, including letterheads, envelopes, and more.", 
    quantity: 20 
  },
];


const ProductList = () => {
  return (
    <div className='product-list'>
      <h1> Products Collections</h1>
      <p>High-quality printing services for all your needs – fast, reliable, and affordable!</p>
      <div className='product-container'>
        {primaryProducts.map((product) => (
          <div key={product.id} className='product-box'>
             <img src={product.image} alt="" />
             <h3>{product.name}</h3>
            <p>{product.description}</p>
             <p>As low as <b>₦{product.price}</b> per {product.quantity}</p>
            <a href={`/products/${product.name}`}><button className='view-More-sec-button'>view category...<SlArrowRight/></button></a>
           
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductList;